.App .navbar-brand {
  font-weight: bold;
}

a.link-logo.navbar-brand {
  color: black;
}

.navbar-default{
  background-color: #4f8edc !important;
  border: unset;
  border-radius: unset;
  color: white !important;
}

.navbar-default .navbar-nav>li>a{
  color: white !important;
  font-size: 14px !important;
}

.navbar-default .navbar-nav>li.active>a{
  color: initial !important
}

.App {
  width: 100vw;
  height: 100vh;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}